exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-edit-profile-js": () => import("./../../../src/pages/edit-profile.js" /* webpackChunkName: "component---src-pages-edit-profile-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-streamplayer-js": () => import("./../../../src/pages/streamplayer.js" /* webpackChunkName: "component---src-pages-streamplayer-js" */),
  "component---src-pages-streamtemplate-js": () => import("./../../../src/pages/streamtemplate.js" /* webpackChunkName: "component---src-pages-streamtemplate-js" */),
  "component---src-pages-tickets-js": () => import("./../../../src/pages/tickets.js" /* webpackChunkName: "component---src-pages-tickets-js" */),
  "component---src-templates-gallery-archive-js": () => import("./../../../src/templates/gallery/archive.js" /* webpackChunkName: "component---src-templates-gallery-archive-js" */),
  "component---src-templates-home-index-js": () => import("./../../../src/templates/home/index.js" /* webpackChunkName: "component---src-templates-home-index-js" */),
  "component---src-templates-match-report-archive-js": () => import("./../../../src/templates/matchReport/archive.js" /* webpackChunkName: "component---src-templates-match-report-archive-js" */),
  "component---src-templates-match-report-index-js": () => import("./../../../src/templates/matchReport/index.js" /* webpackChunkName: "component---src-templates-match-report-index-js" */),
  "component---src-templates-matchcentre-fgr-live-audio-js": () => import("./../../../src/templates/matchcentre/fgr-live-audio.js" /* webpackChunkName: "component---src-templates-matchcentre-fgr-live-audio-js" */),
  "component---src-templates-matchcentre-fgr-live-js": () => import("./../../../src/templates/matchcentre/fgr-live.js" /* webpackChunkName: "component---src-templates-matchcentre-fgr-live-js" */),
  "component---src-templates-matchcentre-fgr-live-video-js": () => import("./../../../src/templates/matchcentre/fgr-live-video.js" /* webpackChunkName: "component---src-templates-matchcentre-fgr-live-video-js" */),
  "component---src-templates-matchcentre-fixtures-js": () => import("./../../../src/templates/matchcentre/fixtures.js" /* webpackChunkName: "component---src-templates-matchcentre-fixtures-js" */),
  "component---src-templates-matchcentre-league-table-js": () => import("./../../../src/templates/matchcentre/league-table.js" /* webpackChunkName: "component---src-templates-matchcentre-league-table-js" */),
  "component---src-templates-matchcentre-results-js": () => import("./../../../src/templates/matchcentre/results.js" /* webpackChunkName: "component---src-templates-matchcentre-results-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-partners-single-js": () => import("./../../../src/templates/partners/single.js" /* webpackChunkName: "component---src-templates-partners-single-js" */),
  "component---src-templates-shop-category-js": () => import("./../../../src/templates/shop/category.js" /* webpackChunkName: "component---src-templates-shop-category-js" */),
  "component---src-templates-shop-product-js": () => import("./../../../src/templates/shop/product.js" /* webpackChunkName: "component---src-templates-shop-product-js" */),
  "component---src-templates-teams-index-js": () => import("./../../../src/templates/teams/index.js" /* webpackChunkName: "component---src-templates-teams-index-js" */),
  "component---src-templates-teams-single-js": () => import("./../../../src/templates/teams/single.js" /* webpackChunkName: "component---src-templates-teams-single-js" */),
  "component---src-templates-tickets-article-js": () => import("./../../../src/templates/tickets/article.js" /* webpackChunkName: "component---src-templates-tickets-article-js" */),
  "component---src-templates-tickets-match-js": () => import("./../../../src/templates/tickets/match.js" /* webpackChunkName: "component---src-templates-tickets-match-js" */),
  "component---src-templates-tickets-travel-js": () => import("./../../../src/templates/tickets/travel.js" /* webpackChunkName: "component---src-templates-tickets-travel-js" */)
}

